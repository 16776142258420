import { Button } from "@/components/Button/Button";
import { ContentLayout } from "@/features/layout/ContentLayout";
import { Container, Heading, Image, Text, VStack } from "@meditect/medibase-components";

type ErrorPageProperties = {
  actionButtonHref?: string;
  actionButtonName?: string;
  description?: string;
  imageSrc?: string;
  showBrand?: boolean;
  title?: string;
};

export const ErrorPage = ({
  actionButtonHref,
  actionButtonName,
  description,
  imageSrc,
  showBrand = true,
  title,
}: ErrorPageProperties): React.ReactElement => {
  return (
    <ContentLayout data-testid="error-page" w={"full"}>
      {showBrand && <Image maxW={64} src="/logo-brand.png" />}
      <Container as={VStack} mt={showBrand ? 16 : 0} spacing={8} textAlign="center">
        {imageSrc && <Image data-testid={"errorPage-image"} src={imageSrc} />}
        {title && <Heading data-testid={"errorPage-title"}>{title}</Heading>}
        {description && <Text data-testid={"errorPage-description"}>{description}</Text>}

        <Button as={"a"} data-testid={"errorPage-button"} href={actionButtonHref ?? "/"}>
          {actionButtonName ?? "Revenir à l'accueil"}
        </Button>
      </Container>
    </ContentLayout>
  );
};
