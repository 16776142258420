import { Type } from "../../domain";
import { EndpointType } from "../BaseEndpoint";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export interface WithUpdateType<T, UpdateT> {
  update(
    id: string,
    body: UpdateT,
    options?: {
      showErrorModal?: boolean;
    },
  ): Promise<T>;
}

export const WithUpdate = <EndpointT extends EndpointType, T, UpdateT = Partial<T>>(
  clazz: EndpointT,
): Type<WithUpdateType<T, UpdateT>> & EndpointT =>
  class extends clazz {
    /**
     * Update an existing resource, supports partial updates
     *
     * @param id ID of the resource to update
     * @param body Payload used to update the existing resource
     */
    update(
      id: string,
      body: UpdateT,
      options?: {
        showErrorModal?: boolean;
      },
    ): Promise<T> {
      return this.request(id, {
        method: "PATCH",
        body,
        ...options,
      });
    }
  };
