import { CreateOrUpdateOrderRecord, OrderRecord } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithUpdate } from "./mixins/WithUpdate";

export class Endpoint extends BaseEndpoint("order-records") {
  delete(id: string): Promise<OrderRecord> {
    return this.request(`${id}`, { method: "DELETE" });
  }
}

export const OrderRecordEndpoint = WithUpdate<typeof Endpoint, OrderRecord, CreateOrUpdateOrderRecord>(Endpoint);
