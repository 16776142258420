import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const solid = defineStyle(({ colorScheme }) =>
  colorScheme === "orange" || colorScheme === "warning" ? { color: "gray.700" } : {},
);

const action = defineStyle({
  _hover: {
    bg: "green.100",
  },
  bg: "green.50",
  border: "1px solid",
  borderColor: "green.500",
  color: "gray.600",
});
const outline = defineStyle(({ colorScheme }) => (colorScheme === "gray" ? { backgroundColor: "white" } : {}));
const ghost = defineStyle(({ colorScheme }) => (colorScheme === "gray" ? { color: "white" } : {}));

const buttonTheme = defineStyleConfig({
  defaultProps: {
    colorScheme: "gray",
  },
  variants: {
    action,
    ghost,
    outline,
    solid,
  },
});

export default buttonTheme;
