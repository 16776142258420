import { EndpointType } from "../BaseEndpoint";
import { Page } from "../domain";
import { GetPageQuery } from "../query";
import { WithGet } from "./WithGet";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const WithGetPage = <EndpointT extends EndpointType, T, PageT = Page<T>>(clazz: EndpointT) => {
  class Base extends clazz {
    /**
     * Get a page of resources
     *
     * @param query the pageable query used to fetch
     */
    getPage(query?: GetPageQuery): Promise<PageT> {
      return this.request("", {
        query,
      });
    }
  }

  return WithGet<typeof Base, T>(Base);
};
