import { Box, chakra, Heading, HStack } from "@chakra-ui/react";
export interface TitleIconProperties {
  className?: string;
  icon: React.ReactElement;
  title: string;
}

export const TitleIcon = chakra((properties: TitleIconProperties): React.ReactElement => {
  return (
    <HStack className={properties.className} w="100%">
      <Box
        alignItems="center"
        bg="blue.100"
        borderRadius={6}
        color="blue.600"
        display="flex"
        h={6}
        justifyContent="center"
        w={6}
      >
        {properties.icon}
      </Box>
      <Heading as="h2" color="black" fontSize="md" ml={4} textAlign="left">
        {properties.title}
      </Heading>
    </HStack>
  );
});
