import { useCallback } from "react";
import ReactGA from "react-ga4";

type eventOption = {
  action?: string;
  label?: string;
  value?: number;
};

class TrackingEvent {
  private static instance: TrackingEvent;
  private initialized = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance(): TrackingEvent {
    if (!TrackingEvent.instance) {
      TrackingEvent.instance = new TrackingEvent();
    }
    return TrackingEvent.instance;
  }

  public initialize(trackingID: string): void {
    if (this.initialized) {
      return;
    }

    if (!trackingID) {
      return;
    }

    ReactGA.initialize(trackingID);
    this.initialized = true;
  }

  public trackEvent(eventName: string, option?: eventOption): void {
    if (!this.initialized) {
      return;
    }
    ReactGA.event({
      action: eventName,
      category: option?.action || "Click",
      label: option?.label,
      value: option?.value,
    });
  }
  public set(fieldObject: Record<string, unknown>): void {
    if (!this.initialized) {
      return;
    }
    ReactGA.set(fieldObject);
  }
}

export const useTrackingEvent = () => {
  const instance = TrackingEvent.getInstance();

  const initialize = useCallback(
    (trackingID: string) => {
      instance.initialize(trackingID);
    },
    [instance],
  );

  const trackEvent = useCallback(
    (eventName: string, option?: eventOption) => {
      instance.trackEvent(eventName, option);
    },
    [instance],
  );

  const setField = useCallback(
    (fieldObject: Record<string, unknown>) => {
      instance.set(fieldObject);
    },
    [instance],
  );

  return { initialize, setField, trackEvent };
};
