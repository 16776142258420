import { apiClient } from "@/features/api/apiClient";
import { PAGINATION_CHUNK_SIZE } from "@/features/offline/const";
import fetchLocalData from "@/features/offline/utils/fetchLocalData";
import { Packaging, PharmacyProduct, StockItem } from "@meditect/medibase-api-client";

import { Slice } from "../boundStore";

export type PharmacyProductSlice = {
  error: boolean;
  fetchPharmacyProducts: () => Promise<void>;
  isFetching: boolean;
  pharmacyProducts: PharmacyProduct[];

  removePharmacyProduct: (pharmacyProduct: PharmacyProduct) => void;
  replacePharmacyProduct: (pharmacyProduct: PharmacyProduct) => void;
  resetData: () => void;
  updatedAt: Date | undefined;
};

const fetchPagePharmacyProducts = async (page: number) =>
  await apiClient.pharmacyProducts.getAll({
    page: page,
  });

const fetchPaginatedPharmaProduct = async () => {
  const data: PharmacyProduct[] = [];

  const firstPage = await fetchPagePharmacyProducts(0);
  let totalPage = firstPage.totalPages - 1;
  const nbChunk = totalPage / PAGINATION_CHUNK_SIZE;
  data.push(...firstPage.items);
  // return the first page when it's the only one
  if (nbChunk <= 0) {
    return data;
  }

  for (let chunk = 0; chunk < nbChunk; chunk++) {
    const pages: number[] = [];
    for (let index = 0; index < PAGINATION_CHUNK_SIZE && totalPage >= 0; index++) {
      pages[index] = totalPage--;
    }
    const res = await Promise.all(
      pages.map(async (page) => {
        // eslint-disable-next-line unicorn/no-await-expression-member
        return (await fetchPagePharmacyProducts(page)).items;
      }),
    );
    data.push(...res.flat());
  }
  return data;
};

export const createPharmacyProductSlice: Slice<PharmacyProductSlice> = (set, get) => ({
  error: false,
  // Fetcher
  fetchPharmacyProducts: async () => {
    await fetchLocalData(set, get, "pharmacyProduct.pharmacyProducts", fetchPaginatedPharmaProduct);
  },
  isFetching: false,
  // State
  pharmacyProducts: [],

  removePharmacyProduct: (pharmacyProduct: PharmacyProduct) =>
    set(({ pharmacyProduct: state }) => {
      const index = state.pharmacyProducts.findIndex((php) => php.id === pharmacyProduct.id);
      if (index !== -1) {
        state.pharmacyProducts.splice(index, 1);
      }
    }),

  // Setter
  replacePharmacyProduct: (pharmacyProduct: PharmacyProduct) =>
    set(({ pharmacyProduct: state }) => {
      const index = state.pharmacyProducts.findIndex((php) => php.id === pharmacyProduct.id);

      if (index === -1) {
        state.pharmacyProducts.push(pharmacyProduct);
        return;
      }
      state.pharmacyProducts[index] = {
        ...pharmacyProduct,
        quantitiesByPackaging: pharmacyProduct.stockItems
          ?.filter((si): si is { packaging: Packaging } & StockItem => !!si.packaging)
          .reduce<Record<string, number>>((quantitiesByPackaging, si) => {
            quantitiesByPackaging[si.packaging.id] = (quantitiesByPackaging[si.packaging.id] ?? 0) + si.quantity;
            return quantitiesByPackaging;
          }, {}),
        quantity: pharmacyProduct.stockItems.reduce((_, si) => (si.packaging?.id ? 0 : si.quantity), 0),
      };
    }),

  resetData: () =>
    set(({ pharmacyProduct: state }) => {
      state.pharmacyProducts = [];
      state.error = false;
      state.updatedAt = undefined;
    }),

  updatedAt: undefined,
});
