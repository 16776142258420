import { differenceInSeconds } from "date-fns";

import { OFFLINE_EXPIRATION } from "../const";
import { BoundStore, Getter, Setter } from "../stores/boundStore";

export default async function fetchLocalData<
  SliceKey extends keyof Pick<BoundStore, "company" | "organization" | "person" | "pharmacyProduct">,
  DataKey extends keyof BoundStore[SliceKey] & string,
>(
  set: Setter<BoundStore>,
  get: Getter<BoundStore>,
  key: `${SliceKey}.${DataKey}`,
  fetch: () => Promise<BoundStore[SliceKey][DataKey]>,
): Promise<void> {
  const sliceKey: SliceKey = key.split(".")[0] as SliceKey;
  const dataKey: DataKey = key.split(".")[1] as DataKey;

  const updatedAt = get()[sliceKey].updatedAt;

  if (!updatedAt || Math.abs(differenceInSeconds(new Date(updatedAt), new Date())) > OFFLINE_EXPIRATION) {
    set((state) => {
      state[sliceKey].isFetching = true;
    });

    try {
      const data = await fetch();
      // Data may be validated
      set((state) => {
        state[sliceKey][dataKey] = data;
        state[sliceKey].updatedAt = new Date();
        state[sliceKey].error = false;
        state[sliceKey].isFetching = false;
      });
    } catch {
      set((state) => {
        state[sliceKey].error = true;
        state[sliceKey].isFetching = false;
      });
    }
  }
}
