import { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import { useClaims } from "@/features/auth/hooks/useClaims";
import { useTrackingEvent } from "@/hooks/useTrackingEvent";
import { PrivateAppLazy } from "@/PrivateAppLazy";
import { PublicAppLazy } from "@/PublicAppLazy";
import { Center, Loader } from "@meditect/medibase-components";

import { DynamicModuleErrorBoundary } from "./components/DynamicModuleErrorBoundary/DynamicModuleErrorBoundary";
import { ConnectivityContextProvider } from "./features/connectivity/components/ConnectivityProvider";

const App = (): React.ReactElement => {
  const [claims, isLoading] = useClaims();
  const { initialize } = useTrackingEvent();

  useEffect(() => {
    initialize("G-CLL4380TZP");
  });

  if (isLoading) {
    return (
      <Center h="full">
        <Loader />
      </Center>
    );
  }

  return (
    <DynamicModuleErrorBoundary>
      <ConnectivityContextProvider>
        <BrowserRouter>
          <Suspense
            fallback={
              <Center h="full">
                <Loader />
              </Center>
            }
          >
            {claims != null && (claims.pharmacyId == null || claims.pin) ? <PrivateAppLazy /> : <PublicAppLazy />}
          </Suspense>
        </BrowserRouter>
      </ConnectivityContextProvider>
    </DynamicModuleErrorBoundary>
  );
};

export default App;
