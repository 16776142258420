import {
  AssociatePharmacyProduct,
  CreatePharmacyProduct,
  GetManyPharmacyProductsQueryParams,
  GetManyQueryParams,
  PharmacyProduct,
  PharmacyProductHistory,
  PharmacyProductPage,
  PharmacyProductStats,
  PharmacyProductStatsById,
  PharmacyProductSummaryPage,
  Timespan,
  UpdatePharmacyProduct,
} from "../dtos";
import { GetManyPharmacyProductByQuantity } from "../dtos/GetManyPharmacyProductByQuantity";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithGet } from "./mixins/WithGet";
import { PharmacyProductStorageEndpoint } from "./PharmacyProductStorageEndpoint";

export class Endpoint extends BaseEndpoint("pharmacy-products") {
  readonly storages = new PharmacyProductStorageEndpoint(this.client);

  getPage(query?: GetManyPharmacyProductsQueryParams): Promise<PharmacyProductSummaryPage> {
    return this.request("", { query });
  }

  getStats(query?: GetManyPharmacyProductsQueryParams): Promise<PharmacyProductStats> {
    return this.request("stats", { query });
  }

  getHistory(id: string, timespan: Timespan): Promise<PharmacyProductHistory> {
    return this.request(`${id}/history`, { query: { timespan } });
  }

  associate(id: string, body: AssociatePharmacyProduct): Promise<PharmacyProduct> {
    return this.request(`${id}/associate`, { method: "POST", body });
  }

  getAll(query: Omit<GetManyQueryParams, "size">): Promise<PharmacyProductPage> {
    return this.request("all", { query });
  }

  getAllByStockQuantity(query: GetManyPharmacyProductByQuantity): Promise<PharmacyProductPage> {
    return this.request("by-stock-quantity", { query });
  }

  getByDeliveryRecordId(id: string): Promise<PharmacyProduct> {
    return this.request(`deliveryRecord/${id}`);
  }

  getProductStats(id: string): Promise<PharmacyProductStatsById> {
    return this.request(`${id}/stats`);
  }
}

const EditEndoint = WithEdit<typeof Endpoint, PharmacyProduct, CreatePharmacyProduct, UpdatePharmacyProduct>(Endpoint);

export const PharmacyProductEndpoint = WithGet<typeof EditEndoint, PharmacyProduct>(EditEndoint);
