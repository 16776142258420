import { GetManyPharmacyProductsQueryParams } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";

export class StockExportEndpoint extends BaseEndpoint("stock-exports") {
  create(query?: GetManyPharmacyProductsQueryParams): Promise<Blob> {
    return this.request("", {
      method: "POST",
      query,
      type: "blob",
    });
  }
  getData(query?: GetManyPharmacyProductsQueryParams): Promise<Blob> {
    return this.request("", { query, type: "blob" });
  }
}
