import { Company, CreateCompany } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithGet } from "./mixins/WithGet";
import { SearchCompaniesQuery } from "./query";

export class Endpoint extends BaseEndpoint("companies") {
  findAll(query?: SearchCompaniesQuery): Promise<Company[]> {
    return this.request("", { query });
  }
  getAll(): Promise<Company[]> {
    return this.request("all");
  }
}

const withGetAll = WithGet<typeof Endpoint, Company>(Endpoint);

export const CompanyEndpoint = WithEdit<typeof withGetAll, Company, CreateCompany>(withGetAll);
