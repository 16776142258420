import _ from "lodash";

import { apiClient } from "@/features/api/apiClient";
import fetchLocalData from "@/features/offline/utils/fetchLocalData";
import { ContractPolicy, Person } from "@meditect/medibase-api-client";

import { Slice } from "../boundStore";

export type PersonSlice = {
  error: boolean;
  fetchPersons: () => Promise<void>;
  isFetching: boolean;
  persons: Person[];

  replacePerson: (person: Person) => void;
  replacePolicy: (personId: string, policy: ContractPolicy) => void;
  resetData: () => void;
  updatedAt: Date | undefined;
};

export const createPersonSlice: Slice<PersonSlice> = (set, get) => ({
  error: false,
  // Fetcher
  fetchPersons: async () => {
    await fetchLocalData(set, get, "person.persons", async () => await apiClient.persons.getAll());
  },
  isFetching: false,
  // State
  persons: [],

  // Setter
  replacePerson: (person: Person) => {
    set(({ person: state }) => {
      const personIndex = state.persons.findIndex((p) => p.id === person.id);
      if (personIndex > -1) {
        state.persons[personIndex] =
          _.isArray(person.policies) && person.policies.length > 0
            ? person
            : {
                ...person,
                policies: state.persons[personIndex].policies,
              };
      } else {
        state.persons.push(person);
      }
    });
  },

  replacePolicy: (personId: string, policy: ContractPolicy) => {
    set(({ person: state }) => {
      const personIndex = state.persons.findIndex((p) => p.id === personId);

      if (personIndex > -1) {
        const policyIndex = state.persons[personIndex].policies.findIndex((p) => p.id === policy.id);

        if (policyIndex > -1) {
          state.persons[personIndex].policies[policyIndex] = policy;
        }
      }
    });
  },

  resetData: () =>
    set(({ person: state }) => {
      state.persons = [];
      state.error = false;
      state.updatedAt = undefined;
    }),

  updatedAt: undefined,
});
