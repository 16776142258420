import {
  ActivityReport,
  ActivityReportError,
  BillingReport,
  FileExport,
  SalesTurnoverStatistics,
  SalesUsage,
  Timespan,
  TopSales,
  UsageQuery,
} from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { BillingReportQuery } from "./query";

export class UsageEndpoint extends BaseEndpoint("usage") {
  getAccountingReport(query?: UsageQuery): Promise<Blob> {
    return this.request("accounting-report", {
      query,
      type: "blob",
    });
  }

  getActivityReport(query?: UsageQuery): Promise<ActivityReport | ActivityReportError> {
    return this.request("activity-report", {
      query,
    });
  }

  getBillingReport(query?: BillingReportQuery): Promise<BillingReport> {
    return this.request("billing-report", {
      query,
    });
  }

  getSalesReport(query?: UsageQuery): Promise<FileExport> {
    return this.request("sales-report", {
      query,
    });
  }

  getSalesReportData(query?: UsageQuery): Promise<unknown[][]> {
    return this.request("sales-report-data", {
      query,
    });
  }

  getSalesUsage(query?: UsageQuery): Promise<SalesUsage> {
    return this.request("sales", {
      query,
    });
  }

  getTopSales(query?: UsageQuery): Promise<TopSales> {
    return this.request("top-sales", {
      query,
    });
  }

  getTurnoverStatistics(query: Timespan): Promise<SalesTurnoverStatistics> {
    return this.request("turnover-statistics", {
      query,
    });
  }
}
