import { createContext, useContext } from "react";

export interface AnalyticsService {
  logEvent?: (eventName: string, eventParameters?: { [k: string]: string }) => void;
  setUserProperties?: (parameters: { [k: string]: string }) => void;
}
export interface AnalyticsContextValue {
  analytics: AnalyticsService;
}
export const AnalyticsContext = createContext<AnalyticsContextValue>({
  analytics: {
    logEvent: (event) => {
      void event;
    },
  },
});
export const useAnalytics = (): AnalyticsService => {
  const context = useContext(AnalyticsContext);
  return context.analytics;
};
