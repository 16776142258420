import { Audit, AuditPage, GetManyAuditQueryParams } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithGet } from "./mixins/WithGet";

export class Endpoint extends BaseEndpoint("audit") {
  getPage(query: GetManyAuditQueryParams): Promise<AuditPage> {
    return this.request("", { query });
  }
}

export const AuditEndpoint = WithGet<typeof Endpoint, Audit>(Endpoint);
