import { FirebaseApp, getApp, getApps, initializeApp } from "firebase/app";
import "web-vitals";
import { create } from "zustand";

import { env as environment } from "./env";

const FIREBASE_CONFIG = {
  apiKey: environment.REACT_APP_FIREBASE_API_KEY,
  appId: environment.REACT_APP_FIREBASE_APP_ID,
  authDomain: environment.REACT_APP_FIREBASE_AUTH_DOMAIN,
  measurementId: environment.REACT_APP_FIREBASE_MEASUREMENT_ID,
  projectId: environment.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: environment.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const getFirebase = (): FirebaseApp => {
  return getApps().length === 0 ? initializeApp(FIREBASE_CONFIG) : getApp();
};

const init = getFirebase();

interface FirebaseStore {
  firebaseInstance: FirebaseApp; // Store Firebase instance
  setFirebaseInstance: (instance: FirebaseApp) => void; // Action to update Firebase instance
}

// Create Zustand store to manage Firebase instance
export const useFirebaseStore = create<FirebaseStore>((set) => ({
  firebaseInstance: init,
  setFirebaseInstance: (instance) => set({ firebaseInstance: instance }), // Update Firebase instance
}));
