import { ContractPolicy, CreateContractPolicy, UpdateContractPolicy } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithCreate } from "./mixins/WithCreate";
import { WithGet } from "./mixins/WithGet";
import { WithUpdate } from "./mixins/WithUpdate";

export class Endpoint extends BaseEndpoint("contract-policies") {}

const withGet = WithGet<typeof Endpoint, ContractPolicy>(Endpoint);

const withCreate = WithCreate<typeof withGet, ContractPolicy, CreateContractPolicy>(withGet);

export const ContractPolicyEndpoint = WithUpdate<typeof withCreate, ContractPolicy, UpdateContractPolicy>(withCreate);
