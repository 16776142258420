import { forwardRef } from "react";

import { Box, BoxProps, Flex, Loader } from "@meditect/medibase-components";

export interface ContentLayoutOptions {
  isLoading?: boolean;
}

export interface ContentLayoutProperties extends ContentLayoutOptions, BoxProps {
  children?: React.ReactNode;
}

export const ContentLayout = forwardRef<HTMLDivElement, ContentLayoutProperties>(
  ({ children, isLoading = false, ...rest }, reference): React.ReactElement => {
    return (
      <Box position="relative" px={{ base: 2, md: 6 }} {...rest} ref={reference}>
        {isLoading ? (
          <Flex
            alignItems="flex-start"
            backdropFilter="blur(6px)"
            bg="overlayLight"
            bottom={0}
            data-testid="content-layout:loader"
            h="100%"
            justifyContent="center"
            left={0}
            paddingTop={20}
            position="absolute"
            right={0}
            top={0}
            transition="0.3s ease"
            w="100%"
            willChange="opacity visibility"
            zIndex="sticky"
          >
            <Loader />
          </Flex>
        ) : (
          children
        )}
      </Box>
    );
  },
);
