import { ComponentStyleConfig } from "@chakra-ui/react";

const tabs: ComponentStyleConfig = {
  baseStyle: () => ({
    tab: {
      _focus: {
        shadow: "none",
      },
      flexBasis: { base: 0, md: "inherit" },
      flexGrow: { base: 1, md: 0 },
    },
  }),
  defaultProps: {
    variant: "enclosed",
  },
  variants: {
    selector: () => ({
      tab: {
        _hover: {
          bg: "gray.50",
        },
        _selected: {
          color: "green.700",
          border: "1px solid",
          borderColor: "green.500",
          backgroundColor: "green.50",
        },
        border: "1px solid",
        borderColor: "transparent",
        borderRadius: "3px",
      },
    }),
    enclosed: (properties) => ({
      tab: {
        _first: {
          ml: { base: 1, md: 0 },
        },
        _last: {
          mr: { base: 1, md: 0 },
        },
        _notLast: {
          mr: 1,
        },
        _selected: {
          backgroundColor: "transparent",
          borderBottomColor: "gray.50",
          borderBottomWidth: "1px",
          borderTopColor: `${properties.colorScheme}.600`,
          borderTopWidth: "4px",
          mt: 0,
        },
        backgroundColor: "gray.100",
        borderColor: "gray.200",
        borderRadius: 0,
        mt: "1px",
        width: "auto",
      },
    }),
    "group-button": () => ({
      tab: {
        "& *": {
          fontSize: "sm",
        },
        _hover: {
          bg: "blue.200",
        },
        _selected: {
          _hover: {
            bg: "blue.50",
          },
          bg: "white",
          borderColor: "blue.700",
          borderWidth: 1,
          color: "blue.700",
        },
        backgroundColor: "blue.100",
        borderRadius: "md",
        color: "blue.700",
        fontWeight: "semibold",
        mx: 0.5,
        whiteSpace: "nowrap",
        width: "auto",
      },
      tablist: {
        bg: "blue.100",
        borderRadius: "md",
        px: 0.5,
        py: 1,
        w: "fit-content",
      },
    }),
  },
};

export default tabs;
