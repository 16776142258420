import { AiOutlineBank } from "react-icons/ai";

import { PaymentMethod } from "@/features/checkout/domain/PaymentMethod";
import { formatUserName } from "@/utils/formatUserName";
import { BillPaymentType, MovementType, PaymentType } from "@meditect/medibase-api-client";
import {
  BuildingIcon,
  CardIcon,
  CashIcon,
  DiscountIcon,
  NoteIcon,
  UploadIcon,
  UserIcon,
} from "@meditect/medibase-components";
import { HealthcareIcon, MobileIcon } from "@meditect/medibase-components/src";

const cash: PaymentMethod = {
  icon: CashIcon,
  movementType: MovementType.CASH,
  tagBackground: "teal.50",
  tagColor: "teal.800",
  theme: "cyan",
  title: "Espèces",
  type: PaymentType.CASH,
};

const cheque: PaymentMethod = {
  getMeta: (payment) => payment.chequeNumber,
  icon: NoteIcon,
  movementType: MovementType.CHEQUE,
  tagBackground: "purple.100",
  tagColor: "purple.800",
  theme: "purple",
  title: "Chèque",
  type: PaymentType.CHEQUE,
};

const transfer: PaymentMethod = {
  icon: AiOutlineBank,
  movementType: MovementType.TRANSFER,
  tagBackground: "gray.100",
  tagColor: "gray.800",
  theme: "neutral",
  title: "Virement",
  type: BillPaymentType.TRANSFER,
};

const mobileMoney: PaymentMethod = {
  getMeta: (payment) =>
    `${payment.phoneOperator ?? ""} ${payment.transactionReference ? `- ${payment.transactionReference}` : ""}`,
  icon: MobileIcon,
  movementType: MovementType.MOBILE_MONEY,
  tagBackground: "pink.50",
  tagColor: "pink.800",
  theme: "pink",
  title: "Mobile",
  type: PaymentType.MOBILE_MONEY,
};

const insurance: PaymentMethod = {
  getMeta: (payment, options) => {
    if (options?.isExpanded) {
      return `${payment.insuranceMetadata?.insurer} - ${payment.insuranceMetadata?.coverage}%`;
    }
    return payment.insuranceMetadata?.insurer;
  },
  icon: HealthcareIcon,
  movementType: MovementType.INSURANCE,
  tagBackground: "blue.100",
  tagColor: "blue.800",
  theme: "info",
  title: "Couverture",
  type: PaymentType.INSURANCE,
};

const creditOrg: PaymentMethod = {
  getMeta: (payment) => {
    return payment.creditMetadata?.organization?.name;
  },
  icon: BuildingIcon,
  movementType: MovementType.CREDIT_ORG,
  tagBackground: "orange.50",
  tagColor: "orange.800",
  theme: "warning",
  title: "Crédit Org.",
  type: PaymentType.CREDIT_ORG,
};

const credit: PaymentMethod = {
  getMeta: (payment) =>
    formatUserName(payment.creditMetadata?.person?.contact, {
      full: false,
    }),
  icon: UserIcon,
  movementType: MovementType.CREDIT,
  tagBackground: "red.100",
  tagColor: "red.800",
  theme: "danger",
  title: "Crédit",
  type: PaymentType.CREDIT,
};

const creditCard: PaymentMethod = {
  icon: CardIcon,
  movementType: MovementType.CREDIT_CARD,
  tagBackground: "cyan.100",
  tagColor: "cyan.800",
  theme: "cyan",
  title: "Carte",
  type: PaymentType.CREDIT_CARD,
};

const disbursement: PaymentMethod = {
  icon: UploadIcon,
  movementType: MovementType.DISBURSEMENT,
  tagBackground: "gray.100",
  tagColor: "gray.800",
  theme: "neutral",
  title: "Décaissement",
  type: MovementType.DISBURSEMENT,
};

const discount: PaymentMethod = {
  icon: DiscountIcon,
  movementType: MovementType.DISCOUNT,
  tagBackground: "green.100",
  tagColor: "green.800",
  theme: "success",
  title: "Remise",
  type: PaymentType.DISCOUNT,
};

export const paymentMethods = {
  cash,
  cheque,
  credit,
  creditCard,
  creditOrg,
  disbursement,
  discount,
  insurance,
  mobileMoney,
  transfer,
};
