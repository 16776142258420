import { Button, chakra, Input, InputGroup, InputProps, InputRightElement } from "@chakra-ui/react";
import { useState } from "react";

import { MaskedIcon, UnMaskedIcon } from "../Icons/Icons";

export type PasswordInputProps = InputProps;

export const PasswordInput = chakra((properties: PasswordInputProps) => {
  const [show, setShow] = useState(false);

  return (
    <InputGroup size="md">
      <Input bg="white" type={show ? "text" : "password"} {...properties} />
      <InputRightElement justifyContent="end" pr={2}>
        <Button
          alignItems="center"
          color="black"
          colorScheme="white"
          display="inline-flex"
          justifyContent="center"
          onClick={() => setShow(!show)}
          size="xs"
          tabIndex={-1}
          type="button"
        >
          {show ? <MaskedIcon /> : <UnMaskedIcon />}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
});
