import { CreateUser, UpdateUser, User } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithEdit } from "./mixins/WithEdit";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";
import { GetAllUsersQuery } from "./query/GetAllUsersQuery";

export class Endpoint extends BaseEndpoint("users") {}

const withEdit = WithEdit<typeof Endpoint, User, CreateUser, UpdateUser>(Endpoint);

const SearchableGetPage = WithSearchableGetPage<typeof withEdit, User>(withEdit);

export class UserEndpoint extends SearchableGetPage {
  getAll(query?: GetAllUsersQuery): Promise<User[]> {
    return this.request("all", { query });
  }
}
