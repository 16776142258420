import { getAuth, getIdTokenResult, onIdTokenChanged } from "firebase/auth";
import { useContext, useEffect, useState } from "react";

import { Claims, ClaimsContext } from "@/features/auth/components/ClaimsContext";
import { useBoundStore } from "@/features/offline/stores/boundStore";
import { SentryContext, SentryContextValue } from "@/features/sentry/SentryProvider";
import { useFirebaseStore } from "@/firebase";
import { ComponentWithChildren } from "@/utils/types/ComponentsWithChildren";

export const ClaimsProvider = ({ children }: ComponentWithChildren) => {
  const firebase = useFirebaseStore((state) => state.firebaseInstance);

  const [isLoading, setIsLoading] = useState(false);
  const [claims, setClaims] = useState<Claims | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const oldPharmacyId = useBoundStore((state: { pharmacyId: any }) => state.pharmacyId);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [resetData, setZustandClaims] = useBoundStore((store: { resetData: any; setClaims: any }) => [
    store.resetData,
    store.setClaims,
  ]);

  const { setMetadata } = useContext<SentryContextValue>(SentryContext);

  useEffect(() => {
    setIsLoading(true);
    const auth = getAuth(firebase);

    const tokenIdListener = onIdTokenChanged(auth, async (user) => {
      if (user == null) {
        setClaims(undefined);
        setZustandClaims(undefined);
      } else {
        try {
          const tokenResult = await getIdTokenResult(user);
          const claims = tokenResult.claims as Claims;
          setClaims(claims);
          setMetadata(user, claims);
          setZustandClaims(claims);

          if (claims.pharmacyId && claims.pharmacyId !== oldPharmacyId) {
            resetData(claims);
          }
        } catch (error) {
          console.error("Error getting ID token result:", error);
        }
      }

      setIsLoading(false);
    });

    return () => {
      if (tokenIdListener) {
        tokenIdListener();
      }
    };
  }, [firebase, oldPharmacyId, resetData, setClaims, setIsLoading, setMetadata, setZustandClaims]);

  return (
    <ClaimsContext.Provider
      value={{
        claims,
        isLoading,
      }}
    >
      {children}
    </ClaimsContext.Provider>
  );
};
