import { del, get, set } from "idb-keyval";
import merge from "lodash/merge";
import { create, StoreApi } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import { Claims } from "@/features/auth/components/ClaimsContext";
import { DATE_WHEN_OFFLINE_KEY } from "@/features/connectivity/components/ConnectivityProvider";

import { CompanySlice, createCompanySlice } from "./slices/companySlice";
import { createOrganizationSlice, OrganizationSlice } from "./slices/organizationSlice";
import { createPersonSlice, PersonSlice } from "./slices/personSlice";
import { createPharmacyProductSlice, PharmacyProductSlice } from "./slices/pharmacyProductSlice";
import { createSaleSlice, SaleSlice } from "./slices/salesSlice";

export type Setter<Store> = (
  partial: ((state: Store) => void) | Partial<Store> | Store,
  replace?: boolean | undefined,
) => void;
export type Getter<Store> = () => Store;

export type Slice<T> = (set: Setter<BoundStore>, get: Getter<BoundStore>, store: StoreApi<BoundStore>) => T;

export type BoundStore = {
  _hasHydrated: boolean;
  claims: Claims | undefined;
  company: CompanySlice;
  fetchAllData: () => Promise<void>;
  organization: OrganizationSlice;
  person: PersonSlice;
  pharmacyId: string | undefined;
  pharmacyProduct: PharmacyProductSlice;
  resetData: (claims: Claims) => void;
  sale: SaleSlice;
  setClaims: (claims: Claims | undefined) => Promise<void>;
  setHasHydrated: (hasHydrated: boolean) => void;
};
/**
 * Using multiple slice is a best practice in the doc (https://github.com/pmndrs/zustand?tab=readme-ov-file#best-practices)
 * Doc about slices: https://github.com/pmndrs/zustand/blob/main/docs/guides/slices-pattern.md
 */
export const useBoundStore = create<BoundStore>()(
  persist(
    immer((set, get, store) => ({
      _hasHydrated: false,
      claims: undefined,
      company: createCompanySlice(set, get, store),
      fetchAllData: async () => {
        // prevent to fetch data offline
        if (localStorage.getItem(DATE_WHEN_OFFLINE_KEY)) {
          return;
        }
        const state = get();

        if (state.claims?.pin && !state.claims.roles?.includes("admin") && state._hasHydrated) {
          state?.pharmacyProduct.fetchPharmacyProducts();

          state?.person.fetchPersons();
          state?.company.fetchCompanies();
          state?.organization.fetchOrganizations();
        }
      },
      organization: createOrganizationSlice(set, get, store),
      person: createPersonSlice(set, get, store),
      pharmacyId: undefined,
      pharmacyProduct: createPharmacyProductSlice(set, get, store),
      resetData: (claims: Claims) => {
        set((state) => {
          state.pharmacyId = claims.pharmacyId;
          state.claims = claims;

          state.company.resetData();
          state.organization.resetData();
          state.person.resetData();
          state.pharmacyProduct.resetData();
          state.sale.resetSales();
        });
      },
      sale: createSaleSlice(set, get, store),
      setClaims: async (claims) => {
        set((state) => {
          state.claims = claims;
        });
        if (claims) {
          get().fetchAllData();
        }
      },
      setHasHydrated: (hasHydrated: boolean) => {
        set((state) => {
          state._hasHydrated = hasHydrated;
        });
      },
    })),
    {
      merge: (persistedState, currentState) => merge(currentState, persistedState),
      name: "zustand-storage",
      onRehydrateStorage: () => (state) => {
        state?.setHasHydrated(true);
      },
      partialize: (state) => ({
        claims: state.claims,
        company: state.company,
        organization: state.organization,
        person: state.person,
        pharmacyId: state.pharmacyId,
        pharmacyProduct: state.pharmacyProduct,
        sale: state.sale,
      }),
      storage: createJSONStorage(() => ({
        getItem: async (name: string): Promise<null | string> => {
          return (await get(name)) || null;
        },
        removeItem: async (name: string): Promise<void> => {
          await del(name);
        },
        setItem: async (name: string, value: string): Promise<void> => {
          await set(name, value);
        },
      })),
    },
  ),
);
