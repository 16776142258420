import { BaseEndpoint } from "./BaseEndpoint";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";
import { CreateSupplier, UpdateSupplier, Supplier, SupplierPage, GetManySuppliersQueryParams } from "../dtos";
import { WithEdit } from "./mixins/WithEdit";

export class Endpoint extends BaseEndpoint("suppliers") {}

const withEdit = WithEdit<typeof Endpoint, Supplier, CreateSupplier, UpdateSupplier>(Endpoint);

export const SupplierEndpoint = WithSearchableGetPage<
  typeof withEdit,
  Supplier,
  SupplierPage,
  GetManySuppliersQueryParams
>(withEdit);
