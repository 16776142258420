import { Bill, CreateBill, UpdateBill } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { Page } from "./domain";
import { WithEdit } from "./mixins/WithEdit";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";
import { GetBillsQuery } from "./query";

export class Endpoint extends BaseEndpoint("bills") {
  getBills(query?: GetBillsQuery): Promise<Page<Bill>> {
    return this.request("", { query });
  }
}

const withRead = WithSearchableGetPage<typeof Endpoint, Bill>(Endpoint);

export const BillEndpoint = WithEdit<typeof withRead, Bill, CreateBill, UpdateBill>(withRead);
