export const ONE_HOUR = 3_600_000;
export const ONE_MINUTE = 60 * 1000;
export const TWO_MINUTES = 60 * 1000 * 2;
export const FIVE_MINUTES = 60 * 1000 * 5;

export const FETCH_ENTITIES_INDEX = 2;

// [ENTITY,ID,UNIQ_SUB_ENTITY]
// vs
// [ENTITY,ID, {UNIQ_SUB_ENTITY:true}]

export const FETCH_ORGANIZATIONS = "organizations";
export const FETCH_ORGANIZATION = "organization";

export const FETCH_CONTRACT_POLICIES = "contractPolicies";

export const FETCH_COMPANIES = "companies";

export const FETCH_PERSONS = "persons";
export const FETCH_PERSONS_RELATIONSHIP = "relationships";

export const FETCH_PHARMACIES = "pharmacies";
export const FETCH_PHARMACY_HISTORY = "history";
export const FETCH_PHARMACY_STATS = "stats";
export const FETCH_PHARMACY_PRODUCTS = "pharmacyProducts";
export const FETCH_PHARMACY_PRODUCTS_STATS = "pharmacyProductsStats";
export const FETCH_PHARMACY_PRODUCT_AUDIT = "pharmacyProduct-audit";
export const FETCH_PHARMACY_STORAGE = "storages";

export const FETCH_ROLES = "roles";

export const FETCH_COUNTRY = "country";
export const FETCH_COUNTRIES = "countries";
export const FETCH_COUNTRIES_SECTIONS = "sections";
export const FETCH_COUNTRIES_SUPPLIERS = "suppliers";

export const FETCH_SALES = "sales";
export const FETCH_SALES_TURNOVER = "sales-turnover";
export const FETCH_SALE = "sale";
export const FETCH_SALE_HISTORY = "history";

export const FETCH_AUDIT = "audit";

export const FETCH_CLIENTS = "clients";

export const FETCH_BILLS = "bills";
export const FETCH_BILLING_REPORT = "billingReport";

export const FETCH_OUTGOING = "outgoing";

export const FETCH_MOUVEMENTS = "mouvements";
export const FETCH_MOUVEMENTS_REPORT = "mouvementReport";

export const FETCH_CASH_REGISTERS = "cashRegisters";
export const FETCH_CASH_REGISTER_REPORT = "cashRegisterReport";
export const FETCH_USER_CASH_REGISTER = "userCashRegister";

export const FETCH_PRODUCTS = "products";
export const FETCH_PRODUCTS_DETAILS = "details";
export const FETCH_PRODUCTS_CATEGORIES = "categories";
export const FETCH_PRODUCTS_VIEWS = "views";

export const FETCH_ORDERS = "orders";
export const FETCH_ORDER_PREPARATION_RECORD = "order-preparation-record";
export const FETCH_ORDER_PREPARATION = "order-preparation";

export const FETCH_USERS = "users";

export const FETCH_INVENTORIES = "inventories";

export const FETCH_BANKS = "banks";

export const FETCH_DISBURSEMENT_REASONS = "disbursementReasons";

export const FETCH_USAGE = "usage";
export const FETCH_USAGE_TOP_SALES = "top-sales";

export const FETCH_WHOLESALERS = "wholesalers";
export const FETCH_WHOLESALER_CLAIMS = "wholesaler-claims";
export const FETCH_WHOLESALER_PRODUCTS = "wholesaler-products";

export const FETCH_REWARD_PROGRAMS = "reward-programs";

export const FETCH_DELIVERIES = "deliveries";
export const FETCH_SUPPLIERS = "suppliers";
