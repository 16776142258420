import { Page } from "./domain";
import { CreateInventory, InventorySummary, UpdateInventory } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithGet } from "./mixins/WithGet";
import { GetPageQuery } from "./query";
import { WithUpdate } from "./mixins/WithUpdate";
import { ReadInventory } from "../dtos/ReadInventory";

export class Endpoint extends BaseEndpoint("inventories") {
  getPage(query?: GetPageQuery): Promise<Page<InventorySummary>> {
    return this.request("", { query });
  }

  create(inventory: CreateInventory): Promise<ReadInventory> {
    return this.request("", {
      method: "POST",
      body: inventory,
    });
  }
}
const withGetOne = WithGet<typeof Endpoint, ReadInventory>(Endpoint);

export const InventoryEndpoint = WithUpdate<typeof withGetOne, ReadInventory, UpdateInventory>(withGetOne);
