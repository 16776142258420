import { ReactElement } from "react";

import { paymentMethods } from "@/features/checkout/const/paymentMethods";
import { BillPaymentType, MovementType, PaymentType } from "@meditect/medibase-api-client";
import { Tag, TagLabel, TagLeftIcon, TagProps, useBreakpointValue } from "@meditect/medibase-components";

export interface PaymentTagProperties extends TagProps {
  collapseBreakpoint?: string;
  type: BillPaymentType | MovementType | PaymentType;
}

export const PaymentTag = ({ collapseBreakpoint = "base", type, ...rest }: PaymentTagProperties): ReactElement => {
  const properties = Object.values(paymentMethods).find((p) => p.type === type);
  if (properties == null) {
    throw new Error("Invalid payment type");
  }

  const isCollapsed = useBreakpointValue({
    base: true,
    [collapseBreakpoint]: false,
  });

  return (
    <Tag background={properties.tagBackground} color={properties.tagColor} h="fit-content" {...rest}>
      <TagLeftIcon as={properties.icon} boxSize="3" mr={isCollapsed ? 0 : undefined} />
      {!isCollapsed && <TagLabel>{properties.title}</TagLabel>}
    </Tag>
  );
};
