import { CreateOrUpdateWholesalerClaim, EntityIdentifier, WholesalerClaim } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { WithCreate } from "./mixins/WithCreate";
import { WithSearchableGetPage } from "./mixins/WithSearchableGetPage";
import { WithUpdate } from "./mixins/WithUpdate";

export class Endpoint extends BaseEndpoint("wholesaler-claims") {}

const withGetAll = WithSearchableGetPage<typeof Endpoint, EntityIdentifier>(Endpoint);

const withUpdate = WithUpdate<typeof withGetAll, WholesalerClaim, CreateOrUpdateWholesalerClaim>(withGetAll);

export const WholesalerClaimsEndpoint = WithCreate<typeof withUpdate, WholesalerClaim, CreateOrUpdateWholesalerClaim>(
  withUpdate,
);
