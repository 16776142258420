import { ErrorMessageComponent } from "@/features/api/domain/ErrorMessageComponent";
import { Text, VStack } from "@meditect/medibase-components";

interface RolesCannotDeleteRoleWithUsersErrorExtra {
  count: number;
}

export const RolesCannotDeleteRoleWithUsersError: ErrorMessageComponent<RolesCannotDeleteRoleWithUsersErrorExtra> = ({
  extra: { count },
}) => {
  return (
    <VStack align="stretch" color="gray.700" textAlign="start">
      <Text>
        La suppression est impossible car {count} {count > 1 ? "membres" : "membre"} {count > 1 ? "ont" : "a"} ce rôle.
      </Text>
      <Text fontWeight={600}>
        La suppression de ce rôle sera possible quand il ne sera plus affecté à aucun membre de la pharmacie.
      </Text>
    </VStack>
  );
};
