import { useBoundStore } from "@/features/offline/stores/boundStore";
import { Sale } from "@meditect/medibase-api-client";

export const getNextId = (localSales: Sale[] | undefined) => {
  if (!localSales || localSales.length === 0) {
    return 1;
  }

  return (
    Math.max(
      ...localSales.map((sale) => {
        if (!sale.id) {
          return 0;
        }
        const saleId = Number.parseInt(sale.id);
        return Number.isNaN(saleId) ? 0 : saleId;
      }),
    ) + 1
  );
};

export const getLocalSales = () => {
  return useBoundStore.getState().sale.sales;
};

export const isEmptySale = (sale: Sale) => {
  return sale.records.length === 0 && sale.payments.length === 0 && !sale.patient;
};

export const createIdentifier = (previous?: Sale): string => {
  const date = new Date();
  if (previous) {
    return `${previous.identifier}-${Number(previous.identifier.split("-")[1] ?? "0") + 1}`;
  }
  return `${date.getFullYear()}${date?.getMonth() + 1}${date.getDate()}${date.getMilliseconds()}${Math.floor(
    Math.random() * 1000,
  )}`;
};
