import { Type } from "../../domain";
import { EndpointType } from "../BaseEndpoint";
import { WithCreate, WithCreateType } from "./WithCreate";
import { WithDelete, WithDeleteType } from "./WithDelete";
import { WithUpdate, WithUpdateType } from "./WithUpdate";

export interface WithEditType<T, CreateT, UpdateT>
  extends WithCreateType<T, CreateT>,
    WithUpdateType<T, UpdateT>,
    WithDeleteType {}

export const WithEdit = <EndpointT extends EndpointType, T, CreateT = T, UpdateT = Partial<CreateT>>(
  clazz: EndpointT,
): EndpointT & Type<WithEditType<T, CreateT, UpdateT>> => {
  const withCreate = WithCreate<EndpointT, T, CreateT>(clazz);
  const withUpdate = WithUpdate<typeof withCreate, T, UpdateT>(withCreate);
  return WithDelete(withUpdate);
};
