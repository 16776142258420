import { getAuth } from "firebase/auth";

import { env } from "@/env";
import { errors } from "@/features/api/const/errors";
import { handleApiError } from "@/features/api/utils/handleApiError";
import { useFirebaseStore } from "@/firebase";
import { ApiClient as Client } from "@meditect/medibase-api-client";

export type RequesterOptions = {
  showErrorModal?: boolean;
};

export const requester = async (url: RequestInfo, options?: RequesterOptions & RequestInit): Promise<Response> => {
  const { showErrorModal, ...init } = {
    ...options,
    showErrorModal: options?.showErrorModal,
  };

  const response = await fetch(url, init);

  if (!response.ok && response.status >= 400 && response.status < 500) {
    const { message, ...extra } = await response.json();
    if (message in errors) {
      if (showErrorModal !== false) {
        handleApiError(message, extra);
      }
      throw new Error(message, { cause: extra });
    }
    throw new Error("Invalid response status: " + response.status);
  }
  return response;
};

const getAuthToken = async () => {
  const firebase = useFirebaseStore.getState().firebaseInstance;
  const firebaseAuth = getAuth(firebase); // Access firebaseAuth from Zustand
  const currentUser = firebaseAuth.currentUser;
  if (currentUser) {
    return await currentUser.getIdToken();
  }
  return null;
};

export const apiClient = new Client(env.REACT_APP_API_URL, {
  authProvider: getAuthToken,
  requester,
});
