import { ErrorMessageComponent } from "@/features/api/domain/ErrorMessageComponent";
import { formatUserName } from "@/utils/formatUserName";
import { Person } from "@meditect/medibase-api-client";
import { ListItem, Text, UnorderedList } from "@meditect/medibase-components";

export interface PersonsArchiveUnclosedBillOrSalesErrorExtra {
  billCount: number;
  hasOutgoing: boolean;
  person: Person;
  saleCount: number;
}

export const PersonsArchiveUnclosedBillOrSalesError: ErrorMessageComponent<
  PersonsArchiveUnclosedBillOrSalesErrorExtra
> = ({ extra: { billCount, hasOutgoing, person, saleCount } }) => {
  const contactName = formatUserName(person.contact);

  return (
    <>
      <Text>
        <Text as="span" mr={1}>
          L’archivage du client
        </Text>
        <Text as="b" mr={1}>
          {contactName}
        </Text>
        <Text as="span">est impossible car ce patient, et ses relations, sont encore impliqués dans :</Text>
      </Text>
      <UnorderedList mb={4} pl={4}>
        {hasOutgoing && (
          <ListItem>
            <Text as="span" mr={1}>
              un
            </Text>
            <Text as="b" mr={1}>
              encours
            </Text>
            <Text as="span">non facturé</Text>
          </ListItem>
        )}
        {saleCount > 0 && (
          <ListItem>
            <Text as="b" mr={1}>
              {saleCount}
            </Text>
            <Text as="span">vente(s) non clôturée(s)</Text>
          </ListItem>
        )}
        {billCount > 0 && (
          <ListItem>
            <Text as="b" mr={1}>
              {billCount}
            </Text>
            <Text as="span">facture(s) non clôturée(s)</Text>
          </ListItem>
        )}
      </UnorderedList>
      <Text>
        <Text as="span" mr={1}>
          Veillez à clôturer ces éléments, et vous pourrez ensuite archiver
        </Text>
        <Text as="b">{contactName}</Text>
        <Text as="span">.</Text>
      </Text>
    </>
  );
};
