import { apiClient } from "@/features/api/apiClient";
import fetchLocalData from "@/features/offline/utils/fetchLocalData";
import { Company } from "@meditect/medibase-api-client";

import { Slice } from "../boundStore";

export type CompanySlice = {
  companies: Company[];
  error: boolean;
  fetchCompanies: () => Promise<void>;
  isFetching: boolean;

  replaceCompany: (comapny: Company) => void;
  resetData: () => void;
  updatedAt: Date | undefined;
};

export const createCompanySlice: Slice<CompanySlice> = (set, get) => ({
  // State
  companies: [],
  error: false,
  // Fetcher
  fetchCompanies: async () => {
    await fetchLocalData(set, get, "company.companies", async () => await apiClient.companies.getAll());
  },
  isFetching: false,

  // Setter
  replaceCompany: (company) => {
    set(({ company: state }) => {
      const companyIndex = state.companies.findIndex((p) => p.id === company.id);
      if (companyIndex > -1) {
        state.companies[companyIndex] = company;
      }
      state.companies.push(company);
    });
  },

  resetData: () =>
    set(({ company: state }) => {
      state.companies = [];
      state.error = false;
      state.updatedAt = undefined;
    }),

  updatedAt: undefined,
});
