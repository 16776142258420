import { FileExport, Movement, MovementOrigin, MovementReport, MovementType, Timespan } from "../dtos";
import { BaseEndpoint } from "./BaseEndpoint";
import { Page } from "./domain";
import { SearchQuery } from "./query";

interface MovementsQuery {
  movementType?: MovementType[];
  movementOrigin?: MovementOrigin[];
  cashRegisterId?: string;
  timespan?: Timespan;
  query?: string;
}

export class MovementEndpoint extends BaseEndpoint("movements") {
  getReport(query: MovementsQuery): Promise<MovementReport> {
    return this.request("report", { query });
  }

  getReportCsv(query: MovementsQuery): Promise<FileExport> {
    return this.request("movement-report-csv", { query });
  }

  getReportData(query: MovementsQuery): Promise<unknown[][]> {
    return this.request("movement-report-data", { query });
  }

  getMovementsPage(query: SearchQuery & MovementsQuery): Promise<Page<Movement>> {
    return this.request("", { query });
  }
}
